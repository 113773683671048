import React from 'react';
import SensorDetails from './SensorDetails';
import './SensorDetailsList.css';

const SensorDetailList = ({ payloads }) => {
	let sevenDaysTemp = [];

	//DATA filtering
	const getSevenDaysData = () => {
		for (let i = 1; i < 7; i++) {
			let d = new Date();
			d.setDate(d.getDate() - i);
			let date = new Date(d).toISOString().slice(8, 10);
			let day = new Date(d).toDateString().slice(0, 3);

			let dayWiseData = payloads
				.filter((el) => {
					return el.commTimestamp.slice(8, 10).includes(date);
				})
				.map((el) => {
					return { temp: el.payload.externalTemp1 };
				});

			let avgTemp =
				dayWiseData.reduce((acc, el) => {
					return acc + el.temp;
				}, 0) / dayWiseData.length;
			const roundedTempVal = Math.round(avgTemp);

			sevenDaysTemp.push({ roundedTempVal, date, day });
		}
	};

	const renderDetails = () => {
		if (payloads.length > 0) {
			getSevenDaysData();
			return sevenDaysTemp.reverse().map((el, idx) => {
				return <SensorDetails key={idx} details={el} />;
			});
		}
		return <p></p>;
	};

	return (
		<React.Fragment>
			<div className="calender">
				<i className="fa fa-calendar calender-icon" />
				<span className="calender-text">The last few days</span>
			</div>
			<div className="sensor-details-list">{renderDetails()}</div>
		</React.Fragment>
	);
};

export default SensorDetailList;
