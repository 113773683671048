import React, { Component } from 'react';
import axios from '../api/axios';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";
import Main from './Main';
import SensorDetailsList from './SensorDetailsList';
import SensorList from './SensorList';
import Banner from './Banner';
import logo from '../assets/viconia.png';
import './App.css';
import LoginContainer from "../containers/login.container";
import DashboardContainer from "../containers/dashboard.container";

class App extends Component {
	state = {
		login: false,
		error: '',
		payloads: [],
		selectedSensor: null,
		currentRecord: null
	};

	sensorItemSelectedHandler = (selectedSensor) => {
		const Token = localStorage.getItem('token');

		const getDate = (previousdate = 0) => {
			let todayDate = new Date();
			let isoDate = todayDate.setDate(todayDate.getDate() - previousdate);
			return new Date(isoDate).toISOString().slice(0, 10);
		};

		axios
			.get(`net/sensors/${selectedSensor.devEui}/values`, {
				headers: { Authorization: `Bearer ${Token}` },
				params: {
					fromDate: getDate(7),
					toDate: getDate()
				}
			})
			.then((response) => {
				let currentRecord;
				let todayDate = new Date().toISOString().slice(0, 10);
				const payloadToday = response.data.filter((el) => {
					return el.commTimestamp.includes(todayDate);
				});
				let timeStampArray = payloadToday.map((el) => {
					return +el.commTimestamp.split('T')[1].split(':').join('');
				});
				let sortedTimeStamp = timeStampArray.sort((a, b) => b - a);

				currentRecord = payloadToday.find((el) => {
					return +el.commTimestamp.split('T')[1].split(':').join('') === sortedTimeStamp[0];
				});
				this.setState({
					payloads: response.data,
					selectedSensor,
					currentRecord
				});
				//BACKEND
				const sensor = {
					sensorId: selectedSensor.devEui,
					temp: `${currentRecord.payload.temperature} degree C`,
					address: {
						longitude: selectedSensor.longitude,
						latitude: selectedSensor.latitude,
						street: selectedSensor.address.street,
						streetNo: selectedSensor.address.streetNo,
						city: selectedSensor.address.city,
						country: selectedSensor.address.country,
						postcode: selectedSensor.address.postcode
					}
				};
				console.log(sensor)
				axios
					.post('https://apipublic.badkruka.nu/api/sensors', sensor)
					.then((res) => console.log(res.data))
					.catch((err) => console.log(err));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	renderMap() {
		if (this.state.login) {
			return <SensorList sensorItemSelected={this.sensorItemSelectedHandler} />;
			// <Map sensorItemSelected={this.sensorItemSelectedHandler} />;
		}
		return <p>{this.state.error}</p>;
	}

	renderSensorDetails() {
		if (this.state.login) {
			return <SensorDetailsList payloads={this.state.payloads} />;
		}
		return <p>{this.state.error}</p>;
	}
	render() {
		return (
			<>
				<div style={{position: "absolute", top: 0, zIndex: 99999, width: "100%", backgroundColor: 'rgba(255,255,255,0.8)'}}>
				</div>
				<Router>
					<Switch>
						<Route exact path="/dashboard">
							<DashboardContainer />
						</Route>
						<Route exact path="/login">
							<LoginContainer />
						</Route>
						<Route path="/">
							<Main />
						</Route>
					</Switch>
				</Router>

			</>
		);
	}
}

export default App;
