import React from "react";
import "./Sensor.css";

const Sensor = ({ sensor, clicked }) => {
    return (
        <div
            className="sensor-item"
            onClick={() => {
                clicked(sensor);
            }}
        >
            <p>{ sensor.address.street}</p>
        </div>
    );
};

export default Sensor;
