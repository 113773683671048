import React, { Component } from 'react';
import axios from '../api/axios';
import Sensor from './Sensor';
// import "./SensorList.css";

export default class SensorList extends Component {
	state = {
		sensors: [],
		error: ''
	};

	componentDidMount() {
		const Token = localStorage.getItem('token');

		const getToken = async () => {
			try {
				const response = await axios.get(`/net/sensors`, {
					headers: {
						Authorization: `Bearer ${Token}`
					}
				})
				.then((response)=>{
					this.props.sensorItemSelected(response.data[0]);
					this.setState({
					sensors: response.data
				});
				})

				// if (response.data.success) {
				//   this.setState({ login: true, error: "" });
				// }
			} catch (err) {
				this.setState({ error: 'Something went wrong' });
				console.log(err);
			}
		};
		getToken();
	}

	renderSensorItem() {
		if (!this.state.error) {
			return this.state.sensors.map((el) => {
				return <Sensor key={el.idSensor} sensor={el} clicked={this.props.sensorItemSelected} />;
			});
		}
		return 'Loading....';
	}

	render() {
		return (
			<>
			</>
		);
	}
}
