import React, {useState, useEffect} from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import ReactMapGL from "react-map-gl";
import { Marker } from "react-map-gl";
import axios from '../api/axios';
import qs from "qs";
import "./Map.css";
import Icon from "../assets/icon.png";
import Modal from 'react-modal';
import BG from "../assets/bg.jpg";

const customBrowserStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : 750,
  }
};

const customMobileStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '80%',
    height                : '65%'
  }
};

const Map = (props) =>  {

  useEffect(() => {
    const clientUUID = qs.parse((window.location.search))["?clientUUID"];

    axios.get('/api/spots/list', {
      params: {
        id: clientUUID,
      }
    })
    .then(async function (response) {
      let res = response.data;
      for (const index in res) {
        const sensor = response.data[index];
        const temperature = await readSensorValue(sensor, true)
        res[index].temperature = temperature;
      }
      setSpots(res);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });  
  }, [])

  useEffect(() => {
    async function fetchSensors() {
      const res = await fetch(`apipublic.badkruka.nu/api/sensors/`);
      const sensors = await res.json();
      setSensors(sensors);
    }
    fetchSensors();
  }, []);

  // State
  const [spots, setSpots] = useState([]);
  const [modalIsOpen,setIsOpen] = useState(false);
  const [viewPort, setViewport] = useState({
      latitude: 58.734404,
      longitude: 12.450342,
      width: "100%",
      height: "100vh",
      zoom: 10,
  });
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [token, setToken] = useState("");
  const [modalTemp, setModalTemp] = useState("Laddar...");

  const[history, setHistory] = useState([]);
  const [spotTemps, setSpotTemps] = useState([]);

  const handleModal = (spot) => 
  {
    setHistory([]);
    setModalTemp(0);
    setSelectedSpot(spot);
    setIsOpen(true);
    readSensorValue(spot);
    console.log(spot)
  }

  const readSensorValue = async (spot, shouldReturn = false) => 
  {
    const selectedSensor = spot;

    if(!selectedSensor) return;

    var d = new Date();
    d.setDate(d.getDate() - 7);

    const res = await fetch(`https://apipublic.badkruka.nu/api/sensors/${selectedSensor.sensorID}`);
    const sensorData = await res.json();
    let temperature = 0;
    if (sensorData[0] && sensorData[0].value) {
      temperature = sensorData.pop().value;
    } else {
      temperature = 'N/A';
    }

    setModalTemp(temperature);

    setHistory(sensorData);

    if (shouldReturn) {
      return temperature;
    }
  }

  const calcAvg = (date) => 
  {

    if(!history || history.length === 0 ) return;

    const payload = history.filter((el) => {
      return el.createdAt.includes(date);
    });

    if (payload.length === 0) return '-';

    const temperatures = payload.map((data) => {
      return data.value
    })

    return Math.max(...temperatures).toFixed(1);

  }

  const presentHistory = () => 
  {
    if(!history || history.length === 0) return;
    var d = new Date();
    d.setDate(d.getDate() - 6);
    let daysAgo6 = d.toISOString().slice(0, 10);

    var d2 = new Date();
    d2.setDate(d2.getDate() - 5);
    let daysAgo5 = d2.toISOString().slice(0, 10);

    var d3 = new Date();
    d3.setDate(d3.getDate() - 4);
    let daysAgo4 = d3.toISOString().slice(0, 10);

    var d4 = new Date();
    d4.setDate(d4.getDate() - 3);
    let daysAgo3 = d4.toISOString().slice(0, 10);

    var d5 = new Date();
    d5.setDate(d5.getDate() - 2);
    let daysAgo2 = d5.toISOString().slice(0, 10);

    var d6 = new Date();
    d6.setDate(d6.getDate() - 1);
    let daysAgo1 = d6.toISOString().slice(0, 10);

    return (
      <div style={{display: "flex", flexDirection: "row", fontSize: 12}}>
        <div style={{marginRight: 10,}}>
          <span style={{fontWeight: "bold"}}>{daysAgo6}</span>
          <br />
          {calcAvg(daysAgo6)} &#176;C
        </div>
        <div style={{marginRight: 10,}}>
          <span style={{fontWeight: "bold"}}>{daysAgo5}</span>
          <br />
          {calcAvg(daysAgo5)} &#176;C
        </div>
        <div style={{marginRight: 10,}}>
          <span style={{fontWeight: "bold"}}>{daysAgo4}</span>
          <br />
          {calcAvg(daysAgo4)} &#176;C
        </div>
        <div style={{marginRight: 10,}}>
          <span style={{fontWeight: "bold"}}>{daysAgo3}</span>
          <br />
          {calcAvg(daysAgo3)} &#176;C
        </div>
        <div style={{marginRight: 10,}}>
          <span style={{fontWeight: "bold"}}>{daysAgo2}</span>
          <br />
          {calcAvg(daysAgo2)} &#176;C
        </div>
        <div >
          <span style={{fontWeight: "bold"}}>{daysAgo1}</span>
          <br />
          {calcAvg(daysAgo1)} &#176;C
        </div>
      </div>
    )
  }


    return (
      <>

        <ReactMapGL
          {...viewPort}
          mapboxApiAccessToken='pk.eyJ1IjoiY29vbDI0NyIsImEiOiJjazg1anZvYWUwMmx3M2htbXFxNWNjNmlwIn0.yiHpp-fXNb4W3CCm9l8VhQ'
          mapStyle="mapbox://styles/cool247/ckb3gpqux0k0j1io63l4gcwar"
          onViewportChange={viewport => {
            setViewport(viewport)
          }}
        >
          {spots.map((spot, index) => {
            return <Marker key={`marker-${index}`} latitude={spot.latitude} longitude={spot.longitude} offsetLeft={-20} offsetTop={-10}>
            <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <img src={Icon} onClick={() => handleModal(spot)} style={{color: '#fff', width: 32, cursor: "pointer"}}  />
              <span style={{color: "#000", marginTop: 5, fontSize: 12, textShadow: "1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff", fontWeight: "bold"}}>{spot.name}</span>
              <span style={{color: "#000", marginTop: -2, fontSize: 12, textShadow: "1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff" }}>{`${spot.temperature} °C`}</span>
            </div>
          </Marker>
          })}
          <BrowserView>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customBrowserStyle}
                contentLabel="Example Modal"
                ariaHideApp={false}
              >
                <div >
                  <img style={{width: "100%"}} src={selectedSpot ? selectedSpot.image.length === 0 ? BG : selectedSpot.image : ""} />
                  <h5 style={{marginTop: 10, marginBottom: 0,}}>{selectedSpot ? selectedSpot.name : BG} </h5>
                  <span style={{fontSize: 12}}>Vattenkvalité: {selectedSpot ? selectedSpot.waterquality : ""}</span>
                  <br />
                  <span style={{fontSize: 12}}>Algförekomst: {selectedSpot ? selectedSpot.algiers === "true" ? "Ja" : "Nej" : ""} (uppdaterad {selectedSpot ? selectedSpot.updatedAt.slice(0,10) : "Ej uppdaterad"})</span>
                  <br />
                  <span style={{fontSize: 12}}>Läs mer om badplatsen <a target="_blank" href={selectedSpot ? selectedSpot.link : ""}>här</a></span>
                </div>
                <div style={{float: "right", marginTop: -100,}}>
                  <h3>{modalTemp} &#176;C</h3>
                </div>
                <div style={{float: "right", marginTop: -68,}}>
                  <h4 style={{fontSize: 18, fontWeight: "bold"}}>Veckans mätvärden</h4>
                  {presentHistory()}
                </div>
            </Modal>
          </BrowserView>

          <MobileView>
          <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customMobileStyle}
                contentLabel="Example Modal"
                ariaHideApp={false}
              >
                <div >
                  <img style={{width: "100%"}} src={selectedSpot ? selectedSpot.image.length === 0 ? BG : selectedSpot.image : ""} />
                  <h5 style={{marginTop: 10, marginBottom: 0,}}>{selectedSpot ? selectedSpot.name : BG} </h5>
                  <span style={{fontSize: 12}}>Vattenkvalité: {selectedSpot ? selectedSpot.waterquality : ""}</span>
                  <br />
                  <span style={{fontSize: 12}}>Algförekomst: {selectedSpot ? selectedSpot.algiers ? "Ja" : "Nej" : ""} (uppdaterad {selectedSpot ? selectedSpot.updatedAt.slice(0,10) : "Ej uppdaterad"})</span>
                  <br />
                  <span style={{fontSize: 12}}>Läs mer om badplatsen <a target="_blank" href={selectedSpot ? selectedSpot.link : ""}>här</a></span>
                </div>
                <div style={{float: "right", marginTop: -100,}}>
                  <h3>{modalTemp} &#176;C</h3>
                </div>
                <div style={{float: "right"}}>
                  <h4 style={{fontSize: 18, fontWeight: "bold"}}>Veckans mätvärden</h4>
                  {presentHistory()}
                </div>
            </Modal>
          </MobileView>
        </ReactMapGL>
      </>
    );
}

export default Map;


