import React from 'react';
import './SensorDetails.css';

const SensorDetails = ({ details }) => {
	return (
		<div className="sensor-details-item">
			<p className="history-day">
				{details.date}, {details.day}
			</p>

			<p className="history-temp">
				{details.roundedTempVal}
				<span className="degree-history">o</span>
			</p>
		</div>
	);
};

export default SensorDetails;
