import React, { useState } from 'react';
import styled from 'styled-components';
import defaultImg from '../assets/bg.jpg';
import './Main.css';
import axios from '../api/axios';
import Map from "../components/Map";
const DIV = styled.div`
	background: url(${(props) => props.img});
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
	/* margin-left: 16px; */
`;

const Main = (props) => {
	const [ imgBinary, setImgBinary ] = useState(null);

	let day = new Date().toDateString().slice(0, 10);

	//img background
	if (props.selectedSensor && !imgBinary) {
		const Token = localStorage.getItem('token');
		axios
			.get(`/net/sensors/${props.selectedSensor.devEui}`, {
				headers: { Authorization: `Bearer ${Token}` }
			})
			.then((res) => {
				axios
					.get(`/physical-file/data64/${res.data.attachments[0].idPhysicalFile}`, {
						headers: { Authorization: `Bearer ${Token}` }
					})
					.then((response) => {
						setImgBinary(response.data);
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	}

	return (
		<DIV img={imgBinary ? 'data:image/jpg;base64,' + imgBinary : defaultImg} className="full-map">
			<Map />
		</DIV>
	);
};

export default Main;
