import React, { Component, useState, useCallback, useEffect } from 'react';
import axios from '../api/axios';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

import BG from "../assets/bg.jpg";
import { useDropzone } from 'react-dropzone'
import qs from "qs";

const DashboardContainer = (props) => {

	const [name, setName] = useState("");
	const [longitude, setLongitude] = useState("");
	const [latitude, setLatitude] = useState("");
	const [quality, setQuality] = useState("Tjänlig");
	const [algiers, setAlgiers] = useState(false);
	const [image, setImage] = useState("");
	const [link, setLink] = useState("");
	const [updated, setUpdated] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [spots, setSpots] = useState([]);
	const [useruuid, setUseruuid] = useState("");
	const [selectedSpot, setSelectedSpot] = useState(0);
	const [added, setAdded] = useState(false);
	const [sensorID, setSensorID] = useState(0);

	useEffect(() => {

		//Authentication

		const token = qs.parse((window.location.search))["?token"];
		if (!token || token === "") {
			window.location = "/login?error=invalidtoken"
		}

		axios.get("/api/auth/verify", { params: { token: token } }).then(function (response) {
			if (!response.data.useruuidid)
				window.location = "/login?error=invalidtoken"
			setUseruuid(response.data.useruuidid);
			axios.get('/api/spots/list', {
				params: {
					id: response.data.useruuidid,
				}
			})
				.then(function (response) {
					setSpots(response.data);
					setName(spots[0].name);
					setSensorID(spots[0].sensorID);
					setLongitude(spots[0].longitude);
					setLatitude(spots[0].latitude);
					setQuality(spots[0].waterquality);
					setLink(spots[0].link);
				})
				.catch(function (error) {
					console.log(error);
				})
				.then(function () {
					// always executed
				});


		}).catch(function (error) {
			window.location = "/login?error=invalidtoken"
		});





	}, []);

	const handleChangeSpot = (spot) => {
		setName(spots[spot].name);
		setSensorID(spots[spot].sensorID);
		setLongitude(spots[spot].longitude);
		setLatitude(spots[spot].latitude);
		setQuality(spots[spot].waterquality);
		setLink(spots[spot].link);
		setSelectedSpot(spot);
	}

	const renderForm = () => {

		if (!spots || spots.length === 0) {
			return (
				<input onClick={() => setSpots([...spots, { name: "Ny badplats" }])} type="button" value="Lägg till ny" style={{ borderRadius: 10, backgroundColor: "steelblue", color: "#fff", padding: 5, border: 0, marginLeft: 10, fontSize: 12 }} />
			)
		}
		return (
			<>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Badplatser</label>
					<br />
					<select onChange={(e) => handleChangeSpot(e.target.value)} style={{ width: "50%" }}>
						<option value={0}>Välj en badplats</option>
						{spots.map((spot, index) => {
							return <option value={index}>{spot.name}</option>
						})}
					</select>
					<input onClick={() => addSpot()} type="button" value={added ? "Ny badplats tillagd!" : "Lägg till ny"} style={{ borderRadius: 10, backgroundColor: "steelblue", color: "#fff", padding: 5, border: 0, marginLeft: 10, fontSize: 12 }} />
				</div>

				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Badplatsens namn</label>
					<br />
					<input value={name} type="text" onInput={(e) => setName(e.target.value)} style={{ width: "100%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Givarens ID</label>
					<br />
					<input value={sensorID} type="text" onInput={(e) => setSensorID(e.target.value)} style={{ width: "100%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Longitud</label>
					<br />
					<input value={longitude} type="text" onInput={(e) => setLongitude(e.target.value)} style={{ width: "50%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Latitud</label>
					<br />
					<input value={latitude} type="text" onInput={(e) => setLatitude(e.target.value)} style={{ width: "50%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Vattenkvalitet</label>
					<br />
					<input value={quality} type="text" placeholder="Tjänligt" onInput={(e) => setQuality(e.target.value)} style={{ width: "50%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Algförekomst</label>
					<br />
					<select onChange={(e) => setAlgiers(e.target.value)} style={{ width: "50%" }}>
						<option value={false}>Nej</option>
						<option value={true}>Ja</option>
					</select>
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Bildlänk</label>
					<br />
					<input value={image} type="text" placeholder="https://" onInput={(e) => setImage(e.target.value)} style={{ width: "100%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<label style={{ fontWeight: "bold" }}>Läs mer länk</label>
					<br />
					<input value={link} type="text" placeholder="https://" onInput={(e) => setLink(e.target.value)} style={{ width: "100%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<input onClick={() => updateSpot()} type="button" value={updated ? "Uppdaterad" : "Uppdatera"} style={{ backgroundColor: "green", color: "#fff", padding: 5, border: 0, width: "100%" }} />
				</div>
				<div style={{ marginTop: 10 }}>
					<input onClick={() => deleteSpot()} type="button" value={deleted ? "Borttagen" : "Ta bort"} style={{ backgroundColor: "red", color: "#fff", padding: 5, border: 0, width: "100%"}} />
				</div>
			</>
		)
	}

	const addSpot = () => {
		const token = qs.parse((window.location.search))["?token"];

		axios.post("/api/spots/add",
			qs.stringify({
				token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkpvYWtpbSIsImlhdCI6MTYxNTA1NDEzN30.nJlt9FSsxYvschfPACMSP9Z6FxcbRuF5_ShbWdQOn5Q",
				name,
				useruuid,
				spotid: spots[selectedSpot].id,
				longitude,
				latitude,
				waterquality: quality,
				algiers,
				image,
				link,
				sensorID
			}), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Bearer ${token}`
			}
		}).then(function (response) {
			if (response.data) {
				setAdded(true);
			}
		}).catch(function (error) {
			setAdded(false);
		});
	}

	const updateSpot = () => {
		const token = qs.parse((window.location.search))["?token"];

		axios.post("/api/spots/update",
			qs.stringify({
				token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkpvYWtpbSIsImlhdCI6MTYxNTA1NDEzN30.nJlt9FSsxYvschfPACMSP9Z6FxcbRuF5_ShbWdQOn5Q",
				name,
				useruuid,
				longitude,
				latitude,
				waterquality: quality,
				algiers,
				image,
				link,
				sensorID
			}), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Bearer ${token}`
			}
		}).then(function (response) {
			if (response.data) {
				setUpdated(true);
			}
		}).catch(function (error) {
			setUpdated(false);
		});
	}

	const deleteSpot = () => {
		const token = qs.parse((window.location.search))["?token"];

		axios.post("/api/spots/delete",
			qs.stringify({
				token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkpvYWtpbSIsImlhdCI6MTYxNTA1NDEzN30.nJlt9FSsxYvschfPACMSP9Z6FxcbRuF5_ShbWdQOn5Q"
			}), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Bearer ${token}`
			}
		}).then(function (response) {
			if (response.data) {
				setDeleted(true);
			}
		}).catch(function (error) {
			setDeleted(false);
		});
	}


	return (
		<div style={{ marginTop: 50, height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundImage: `url(${BG})` }}>
			<div style={{ width: 600, padding: 20, backgroundColor: "#fff", borderRadius: 10 }}>
				{renderForm()}
			</div>
		</div>
	);
}


export default DashboardContainer;
