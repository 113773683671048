import React from 'react';
import StraightBanner from '../assets/kontakt.png';
import './Banner.css';

const Banner = () => {
	return (
		// ADD here To make any CHanges In BAnner Section
		// <a href="https://viconia.se" target="_blank"><img src={StraightBanner} alt="banner" /></a>
		<div className="banner">
		</div>
	);
};

export default Banner;
